<template>
    <div style="width:100%;height:100%;">
        <div id="box">
            <div class="header_con">

            </div>
            <div class="all-charts" id="all-charts">
                <div>
                    <div class="all-charts-top">
                        <div v-for="(i,k) in topPicArr" :key="k" class="all-charts-back">
                            <div class="all-charts-back-child">
                                <img :src="i.img" alt="">
                                <p style="margin-top:5px;">{{i.num}}</p>
                                <p>{{i.status}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="all-charts-six-box">
                        <h3>党群服务中心覆盖率</h3>
                        <div class="all-charts-six">
                            <div class="" v-for="(item,index) in serverObj" :key="index">
                                <canvas :id="'my-canvas' + index"></canvas>
                                <p style="font-size:1.5vh;font-weight: normal;color:rgb(183, 205, 240);">{{item.name}}
                                </p>
                                <!-- <p style="font-size:15px;font-weight: 600;margin-top:3px;">{{item.content}}</p> -->
                            </div>
                        </div>
                    </div>
                    <div class="all-charts-line-box">
                        <h3>党群服务中心平均年龄及学历统计</h3>
                        <div class="all-charts-line">
                            <div id="main" style="width:60%;height:150px;"></div>
                            <div id="main_cake" style="width:40%;height:150px;"></div>
                        </div>
                    </div>
                    <div class="all-charts-progress-box">
                        <h3>党群服务中心工作人员统计</h3>
                        <div class="all-charts-progress" v-for="(item,index) in workmanArr" :key="index">
                            <div class="all-charts-progress-title">
                                <div>{{item.titleNum}}</div>
                                <div>
                                    <span>{{item.name}}</span>
                                    <span>{{item.word}}</span>
                                </div>
                            </div>
                            <div class="all-charts-progress-line">
                                <div class="line-border" :style="{'width':item.width * 100 + '%'}"></div>
                                <span>{{item.num}}/专职工作人数</span>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
            <!-- BMAP_SATELLITE_MAP  BMAP_HYBRID_MAP -->
            <div class="all-map">
                <div class="bm-view-div">
                    <div id='container'></div>
                    <div id="container_mosha"></div>
                    <div id="container_bg"></div>
                    <div id="showBox_box" v-if="showBox">
                        <h4>{{showBox_name}}</h4>
                        <div class="showBox_con">
                            {{showBox_content}}
                        </div>
                        <div id="swiper">
                            <carousel-3d :autoplay="true" :animationSpeed="1000" :height="200" width="500"
                                :autoplayTimeout="5000" :display="3" style="margin:0 auto;" :perspective="0"
                                :inverseScaling="200">
                                　　<slide v-for="(slide, i) in slides" :key="i" :index="i"
                                    style="width: 228px;height: 196px;background-color:rgba(255,255,255,0);border:4px solid #00b7ff;border-radius:8px;">
                                    <img :src="slide" style="width:100%;height:100%;border-radius:8px;" />
                                </slide>
                            </carousel-3d>
                        </div>
                    </div>
                </div>
            </div>
            <div class="all-right">
                <div class="all-right-center-box">
                    <h3>党群服务中心占地面积(m²)统计</h3>
                    <div id="histogram" style="width:100%;height:150px;"></div>
                </div>
                <div class="all-right-center-box">
                    <h3>党群服务中心等级评定</h3>
                    <div id="caseShape" style="width:100%;height:200px;"></div>
                </div>
                <div class="all-right-center-box">
                    <h3>党群服务中心信息汇总表</h3>
                    <dv-scroll-board :config="config_center" v-if="center_show"
                        style="width:100%;height:250px;margin:0 auto;margin-top:10px;" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import CircleProgressBar from "../../utils/index.js";
    import { adress, bigdata } from "../../api";
    import * as echarts from 'echarts';
    import { Carousel3d, Slide } from 'vue-carousel-3d'
    import AMapLoader from '@amap/amap-jsapi-loader';
    export default {
        components: {
            Carousel3d,
            Slide
        },
        data() {
            return {
                dataArr: [],
                slides: [
                    {
                        src: require('../../assets/icon/1.png'),
                    },
                    {
                        src: require('../../assets/icon/2.png'),
                    },
                    {
                        src: require('../../assets/icon/3.png'),
                    },

                ],
                center_show: false,
                mapStyle: {
                    styleJson: [
                        {
                            "featureType": "all",
                            "elementType": "geometry",
                            "stylers": {
                                "hue": "#007fff",
                                "saturation": 89
                            }
                        },
                        {
                            "featureType": "water",
                            "elementType": "all",
                            "stylers": {
                                "color": "#ccdfff"
                            }
                        },
                        {
                            "featureType": "all",
                            "elementType": "labels.text.fill",
                            "stylers": {
                                "color": "#939ef0"
                            }
                        }
                    ]
                },
                topPicArr: [
                    {
                        img: require("../../assets/102.png"),
                        num: "22",
                        status: "正常运行"
                    },
                    {
                        img: require("../../assets/103.png"),
                        num: "2",
                        status: "在建"
                    },
                    {
                        img: require("../../assets/101.png"),
                        num: "0",
                        status: "拟建设"
                    },
                ],
                objData: [
                    {
                        lng: "121.516400",
                        lat: "29.936213",
                        name: "洪塘湾党群人才服务中心",
                        active: false
                    },
                    {
                        lng: "121.509658",
                        lat: "29.934344",
                        name: "洪塘街党群人才服务中心",
                        active: false
                    },
                    {
                        lng: "121.516739",
                        lat: "29.98164",
                        name: "安山村党群服务中心",
                        active: false
                    },
                ],
                showBox: false,
                defaultCoord: { lng: 121.51069518889, lat: 29.935931131809 },//默认坐标
                zoom: 18,
                config: {
                    data: [20],
                    shape: 'round',
                    waveOpacity: 0.8,
                    colors: ['#00BAFF', '#3DE7C9']
                },
                timeInterval: null,
                serverObj: [
                    {
                        name: "镇乡（街道）",
                        num: 70,
                        content: "100万+"
                    },
                    {
                        name: "行政村",
                        num: 69,
                        content: "141万+"
                    },
                    {
                        name: "社区",
                        num: 26,
                        content: "54万+"
                    },
                    {
                        name: "两新组织",
                        num: 39,
                        content: "38万+"
                    },
                ],
                workmanArr: [
                    {
                        name: "镇乡(街道)",
                        word: "/工作总人数",
                        propress: "20%",
                        num: 3,
                        titleNum: 2,
                        width: 0
                    },
                    {
                        name: "行政村",
                        word: "/工作总人数",
                        propress: "31%",
                        num: 40,
                        titleNum: 12,
                        width: 0
                    },
                    {
                        name: "社区",
                        word: "/工作总人数",
                        propress: "49%",
                        num: 30,
                        titleNum: 116,
                        width: 0
                    },
                    {
                        name: "两新社区",
                        word: "/工作总人数",
                        propress: "49%",
                        num: 7,
                        titleNum: 5,
                        width: 0
                    }
                ],
                config_center: {
                    header: [' ', '名称', '具体地址', '负责人姓名'],
                    headerBGC: "#28466a",
                    data: [],
                    columnWidth: [25],
                    align: ['center'],
                    oddRowBGC: "rgba(77, 166, 255, .3)",
                    evenRowBGC: "rgba(41, 71, 106,.3)",
                    rowNum: 6,
                    showBox_content: "",
                    showBox_name: "",
                    timmerOneAnim: null,
                    timmerOneAnim2: null
                },
                map: ""

            }
        },
        mounted() {
            adress().then(res => {
                for (let i = 0; i < res.date.length; i++) {
                    res.date[i].active = false;
                }
                this.objData = res.date;
                this.getMap();
            })
            this.getData();
            var _this = this;
            var ws = new WebSocket('ws://8.142.168.191:8001');
            ws.onmessage = function (e) {
                // console.log("收到的消息:" + JSON.parse(e.data).token)
                if ("收到的消息:" + JSON.parse(e.data).token) {
                    let data = JSON.parse(e.data);
                    console.log(data.id)
                    if (data.id != undefined) {
                        _this.draw(data.id);
                    }
                } else {
                    return
                }

            }
        },
        methods: {
            getMap() {
                AMapLoader.load({
                    "key": "f3d5abf9541a591cf75c2be4fa6af225",              // 申请好的Web端开发者Key，首次调用 load 时必填
                    "version": "2.0",   // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
                    "plugins": [],           // 需要使用的的插件列表，如比例尺'AMap.Scale'等
                    "AMapUI": {             // 是否加载 AMapUI，缺省不加载
                        "version": '1.1',   // AMapUI 缺省 1.1
                        "plugins": [],       // 需要加载的 AMapUI ui插件
                    },
                    "Loca": {                // 是否加载 Loca， 缺省不加载
                        "version": '2.0'  // Loca 版本，缺省 1.3.2
                    },
                }).then((AMap) => {
                    this.map = new AMap.Map('container', {
                        mapStyle: 'amap://styles/33acdb1dc354c27478db04942e1d7ef1',
                        zoom: 17,
                        center: [121.51069518889, 29.935931131809],
                        buildingAnimation: true,//楼块出现是否带动画
                        animateEnable: 'panBy',
                        pitch: 50, // 地图俯仰角度，有效范围 0 度- 83 度
                        viewMode: '3D', // 地图模式
                    })
                    let that = this;
                    this.objData.forEach(function (marker) {
                        console.log(marker)
                        var markers = new AMap.Marker({
                            map: that.map,
                            position: [marker.lng, marker.lat],   // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
                            icon: require("../../assets/0.png"),
                            title: marker.name,
                        })
                        that.map.add(markers);
                    });
                }).catch(e => {
                    console.log(e);
                })
            },
            getData() {
                bigdata().then(res => {
                    console.log(res.data);
                    this.dataArr = res.data;
                    var _this = this;
                    setTimeout(function () {
                        _this.workmanArr[0].width = Number(res.data.staff[0].full_staff) / (Number(res.data.staff[0].full_staff) + Number(res.data.staff[0].staff));
                        _this.workmanArr[0].num = res.data.staff[0].full_staff
                        _this.workmanArr[1].width = Number(res.data.staff[1].full_staff) / (Number(res.data.staff[1].full_staff) + Number(res.data.staff[1].staff));
                        _this.workmanArr[1].num = res.data.staff[1].full_staff
                        _this.workmanArr[2].width = Number(res.data.staff[2].full_staff) / (Number(res.data.staff[2].full_staff) + Number(res.data.staff[2].staff));
                        _this.workmanArr[2].num = res.data.staff[2].full_staff
                        _this.workmanArr[3].width = Number(res.data.staff[3].full_staff) / (Number(res.data.staff[3].full_staff) + Number(res.data.staff[3].staff));
                        _this.workmanArr[3].num = res.data.staff[3].full_staff
                    }, 300)
                    this.dataArr.list.forEach(item => {
                        let arr = [];
                        if (item.status == "正常运行") {
                            arr = ['<span class="radius_box" style="background-color:green;"></span>', item.name, item.address, item.charge_realname]
                        } else if (item.status == "在建") {
                            arr = ['<span class="radius_box"></span>', item.name, item.address, item.charge_realname]
                        } else if (item.status == "拟建") {
                            arr = ['<span class="radius_box" style="background-color:gray;"></span>', item.name, item.address, item.charge_realname]
                        }
                        this.config_center.data.push(arr)
                        this.center_show = true;
                    })
                    this.linkDraw();
                    this.cakeDraw();
                    this.histogramDraw();
                    this.caseShapeDraw();
                    this.getCanvasDraw();
                })
            },
            movestart(type) {
                console.log(type.target)
            },
            draw(index) {
                this.showBox = false;
                this.zoom = 20;//缩放程度
                let _this = this;
                setTimeout(function () {
                    _this.showBox = true;
                })
                this.slides = this.objData[index].image;
                this.showBox_content = this.objData[index].content;
                this.showBox_name = this.objData[index].name;
                // this.objData[index].active = true;
                this.map.setCenter([this.objData[index].lng, this.objData[index].lat]);
            },
            infoWindowClose(index) {
                this.showBox = false;
                this.objData[index].active = false;
            },
            infoWindowOpen(index) {
                this.showBox = true;
                this.objData[index].active = true;
            },
            keyCodeMove(dir) {
                if (dir == "右") {
                    this.defaultCoord.lng += 0.001;
                }
            },
            getCanvasDraw() {
                this.serverObj[0].num = this.dataArr.zxcount;
                this.serverObj[1].content = this.dataArr.zxcount;
                this.serverObj[1].num = this.dataArr.xzccount;
                this.serverObj[2].num = this.dataArr.sqcount;
                this.serverObj[3].num = this.dataArr.lxzzcount;
                this.serverObj.forEach(e => {
                    let id = '#my-canvas' + this.serverObj.indexOf(e)
                    var myCanvas = document.querySelector(id);
                    new CircleProgressBar({
                        canvasDom: myCanvas,
                        angle: Math.PI * (e.num / 50),
                        duration: 800,
                        textFontSize: 14,
                        showPercent: true,
                        lineWidth: 6,
                        animationMode: 'accelerate'
                    });
                });
            },
            linkDraw() {
                var chartDom = document.getElementById('main');
                var myChart = echarts.init(chartDom);
                var option = {
                    xAxis: {
                        type: 'category',
                        data: ['镇乡(街道)', '行政村', '社区', '两新组织'],
                        axisLine: {  //这是x轴文字颜色
                            lineStyle: {
                                color: "rgb(21, 191, 254)",
                            }
                        },
                        axisLabel: {
                            interval: -10,    //强制文字产生间隔
                            textStyle: {    //文字样式
                                color: "#fff",
                                fontSize: 10,
                            }
                        }
                    },
                    tooltip: {
                        trigger: 'axis',
                        formatter: '{c0}岁'
                    },
                    yAxis: {
                        type: 'value',
                        scale: true,
                        splitLine: {
                            //网格线
                            lineStyle: {
                                type: "dashed", //设置网格线类型 dotted：虚线   solid:实线
                                width: 0.5,
                                color: 'rgb(21, 191, 254)'
                            },
                            show: true, //隐藏或显示
                        },
                        axisLabel: {
                            textStyle: {    //文字样式
                                color: "#fff",
                            }
                        }
                    },
                    grid: {
                        left: '2%',
                        right: '2%',
                        top: '14%',
                        height: 130,
                        containLabel: true,

                    },
                    series: [{
                        data: this.dataArr.age,
                        label: {
                            show: true,
                            position: 'top',
                            formatter: '{c}岁',
                            textStyle: {
                                fontSize: 10,
                                color: '#fff',
                            }
                        },
                        type: 'line',
                        itemStyle: {
                            type: 'dotted',
                            normal: {
                                color: '#FFAD00',
                                lineStyle: {
                                    color: '#FFAD00'
                                }
                            }
                        },
                    }]
                };
                option && myChart.setOption(option);
                window.addEventListener("resize", function () {
                    myChart.resize()
                });
            },
            cakeDraw() {
                var chartDom = document.getElementById('main_cake');
                var myChart = echarts.init(chartDom);
                var option = {
                    tooltip: {
                        trigger: 'item'
                    },
                    legend: {
                        bottom: '-2%',
                        left: 'center',
                        itemHeight: 10,
                        itemWidth: 10,
                        textStyle: { //图例文字的样式
                            color: '#fff',
                            fontSize: 10
                        },
                    },
                    series: [
                        {
                            center: ['50%', '40%'],
                            name: '访问来源',
                            type: 'pie',
                            radius: ['55%', '70%'],
                            avoidLabelOverlap: false,

                            label: {
                                show: false,
                                position: 'center'
                            },
                            emphasis: {
                                label: {
                                    show: true,
                                    fontSize: 10,
                                    fontWeight: 'bold'
                                }
                            },
                            labelLine: {
                                show: false
                            },

                            data: [
                                { value: this.dataArr.education.zhuanke, name: '专科', selected: true, itemStyle: { color: '#feac01' } },
                                { value: this.dataArr.education.benke, name: '本科及以上', itemStyle: { color: '#7bc8dc' } },
                                { value: this.dataArr.education.gaozhong, name: '高中', itemStyle: { color: '#15befd' } },
                                { value: this.dataArr.education.chuzhong, name: '初中及以下', itemStyle: { color: '#2447e9' } },
                            ]
                        }
                    ]
                };

                option && myChart.setOption(option);
                // window.addEventListener("resize", function () {
                //     myChart.resize()
                // });
                myChart.dispatchAction({
                    type: 'highlight',
                    seriesIndex: 0,
                    dataIndex: 0
                });
                let index = 0;

                myChart.on('mouseover', function (e) {
                    clearInterval(this.timeInterval)
                    this.timeInterval = null;
                    myChart.dispatchAction({ type: 'downplay', seriesIndex: 0, dataIndex: 0 });
                    if (e.dataIndex != index) {
                        myChart.dispatchAction({ type: 'downplay', seriesIndex: 0, dataIndex: index });
                    }
                    if (e.dataIndex == 0) {
                        myChart.dispatchAction({ type: 'highlight', seriesIndex: 0, dataIndex: e.dataIndex });
                    }
                });

                //当鼠标离开时，把当前项置为选中 
                myChart.on('mouseout', function (e) {
                    index = e.dataIndex;
                    myChart.dispatchAction({ type: 'highlight', seriesIndex: 0, dataIndex: e.dataIndex });
                    setInterval(this.timeInterval, 2000)
                });
                this.chartMove(index, option.series[0].data, myChart);
                // timer = function () {

                //     if (index < option.series[0].data.length - 1) {
                //         index++;
                //         myChart.dispatchAction({ type: 'downplay', seriesIndex: 0, dataIndex: 0 });
                //         myChart.dispatchAction({ type: 'downplay', seriesIndex: 0, dataIndex: 1 });
                //         myChart.dispatchAction({ type: 'downplay', seriesIndex: 0, dataIndex: 2 });
                //         myChart.dispatchAction({ type: 'downplay', seriesIndex: 0, dataIndex: 3 });
                //         myChart.dispatchAction({ type: 'highlight', seriesIndex: 0, dataIndex: index });
                //     } else {
                //         index = 0;
                //         myChart.dispatchAction({ type: 'downplay', seriesIndex: 0, dataIndex: 1 });
                //         myChart.dispatchAction({ type: 'downplay', seriesIndex: 0, dataIndex: 2 });
                //         myChart.dispatchAction({ type: 'downplay', seriesIndex: 0, dataIndex: 3 });
                //         myChart.dispatchAction({ type: 'highlight', seriesIndex: 0, dataIndex: 0 });
                //     }
                //     myChart.setOption(option);
                //     console.log(index)

                // }

            },
            chartMove(index, data, myChart) {
                this.timeInterval = setInterval(() => {
                    if (index < data.length - 1) {
                        index++;
                        myChart.dispatchAction({ type: 'downplay', seriesIndex: 0, dataIndex: 0 });
                        myChart.dispatchAction({ type: 'downplay', seriesIndex: 0, dataIndex: 1 });
                        myChart.dispatchAction({ type: 'downplay', seriesIndex: 0, dataIndex: 2 });
                        myChart.dispatchAction({ type: 'downplay', seriesIndex: 0, dataIndex: 3 });
                        myChart.dispatchAction({ type: 'highlight', seriesIndex: 0, dataIndex: index });
                    } else {
                        index = 0;
                        myChart.dispatchAction({ type: 'downplay', seriesIndex: 0, dataIndex: 1 });
                        myChart.dispatchAction({ type: 'downplay', seriesIndex: 0, dataIndex: 2 });
                        myChart.dispatchAction({ type: 'downplay', seriesIndex: 0, dataIndex: 3 });
                        myChart.dispatchAction({ type: 'highlight', seriesIndex: 0, dataIndex: 0 });
                    }
                }, 5000)
            },
            histogramDraw() {
                var chartDom = document.getElementById('histogram');
                var myChart = echarts.init(chartDom);
                var option;
                option = {
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                            type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
                        }
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        top: '10%',
                        height: 130,
                        containLabel: true
                    },
                    xAxis: [
                        {
                            type: 'category',
                            data: ['300以下', '300-500', '500-700', '700-1000'],
                            axisTick: {
                                alignWithLabel: true
                            },
                            axisLine: {  //这是x轴文字颜色
                                lineStyle: {
                                    color: "rgb(21, 191, 254)",
                                }
                            },
                            axisLabel: {
                                interval: -10,    //强制文字产生间隔
                                textStyle: {    //文字样式
                                    color: "#fff",
                                    fontSize: 13,
                                }
                            }
                        },
                    ],
                    yAxis: [
                        {
                            type: 'value',
                            axisLabel: {
                                interval: -10,    //强制文字产生间隔
                                textStyle: {    //文字样式
                                    color: "#fff",
                                    fontSize: this.$fontSize.fontSize(0.08),
                                }
                            },
                            splitLine: {
                                //网格线
                                lineStyle: {
                                    type: "dashed", //设置网格线类型 dotted：虚线   solid:实线
                                    width: .7,
                                    color: 'rgb(21, 191, 254)'
                                },
                                show: true, //隐藏或显示
                            },
                        }
                    ],
                    series: [
                        {
                            name: ['300以下', '300-500', '500-700', '700-1000'],
                            type: 'bar',
                            barWidth: '30%',
                            data: this.dataArr.covered,
                            itemStyle: {
                                type: 'dotted',
                                normal: {
                                    color: '#ffad01',

                                }
                            },
                        }
                    ]
                };

                option && myChart.setOption(option);

                var count = 0;
                if (this.timmerOneAnim) {
                    clearInterval(this.timmerOneAnim);

                }
                this.timmerOneAnim = setInterval(() => {
                    myChart.dispatchAction({
                        type: "showTip",
                        seriesIndex: 0,
                        dataIndex: count % 4
                    });
                    count++;
                }, 4000);
            },
            caseShapeDraw() {
                var chartDom = document.getElementById('caseShape');
                var myChart = echarts.init(chartDom);
                var option;
                option = {
                    visualMap: {
                        show: false,
                        min: 80,
                        max: 600,
                        inRange: {
                            colorLightness: [0, 1]
                        }
                    },
                    tooltip: {
                        trigger: 'item',
                    },
                    legend:
                    {
                        orient: 'vertical',
                        x: 'left',      //可设定图例在左、右、居中
                        y: 'center',
                        itemHeight: 10,
                        itemWidth: 10,
                        itemGap: 10,
                        icon: 'circle',
                        borderRadius: "50%",
                        textStyle: { //图例文字的样式
                            color: '#fff',
                            fontSize: 12
                        },

                    },

                    series: [
                        {
                            type: 'pie',
                            radius: '70%',
                            center: ['50%', '50%'],
                            data: [
                                { value: this.dataArr.level.level3, name: '3A', selected: true, itemStyle: { color: '#feae01' } },
                                { value: this.dataArr.level.level4, name: '4A', itemStyle: { color: '#15c1fd' } },
                                { value: this.dataArr.level.level5, name: '5A', itemStyle: { color: '#184dff' } },
                                { value: this.dataArr.level.level, name: '其他', itemStyle: { color: '#6ddffe' } },
                            ].sort(function (a, b) { return a.value - b.value; }),
                            label: {
                                color: 'rgba(255, 255, 255, 0.3)',
                                show: false
                            },
                            labelLine: {
                                show: false
                            },
                            itemStyle: {
                                color: '#c23531',
                                shadowBlur: 200,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            },

                            animationType: 'scale',
                            animationEasing: 'elasticOut',
                            animationDelay: function () {
                                return Math.random() * 200;
                            }
                        }
                    ]
                };

                option && myChart.setOption(option);
                var count = 0;
                if (this.timmerOneAnim2) {
                    clearInterval(this.timmerOneAnim2);

                }
                this.timmerOneAnim2 = setInterval(() => {
                    myChart.dispatchAction({
                        type: "showTip",
                        seriesIndex: 0,
                        dataIndex: count % 4
                    });
                    count++;
                }, 5000);


            }
        },

    }
</script>
<style>
    #app,
    #box {
        width: 100%;
        height: 100%;
        overflow: hidden;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    #box {
        height: 100%;
        position: relative;
    }

    .header_con {
        width: 100%;
        height: 100px;
        background: url("../../assets/header.png");
        background-size: 100% 100%;
        position: relative;
        color: #fff;
        position: absolute;
        z-index: 10;
        top: -160px;
        animation: moveDown 1s ease-out;
        animation-fill-mode: forwards;
    }

    @keyframes moveDown {
        0% {
            top: -160px;
        }

        100% {
            top: 0
        }
    }

    .header_con_title {
        position: absolute;
        left: 50%;
        transform: translate(-50%);
        bottom: 50%;
        font-size: 1.7vw;
        font-weight: bold;
        text-align: center;
        overflow: hidden;
    }

    .header_con_title div {
        background-image: -webkit-linear-gradient(bottom, rgb(118, 195, 255), rgb(255, 255, 255));
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        letter-spacing: .4vw;
    }

    body,
    html {
        overflow: hidden;
    }

    #container {
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

    .container {
        padding: 40px;
        padding-top: 100px;
        display: flex;
        justify-content: center;
    }

    #container_mosha {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background: url("../../assets/mosha.png");
        opacity: .5;
    }

    #container_bg {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        /* background:url("../../assets/light3.png");
        opacity: 0.2; */
        background-image: linear-gradient(180deg, #0A204E 0%, rgba(37, 54, 76, .08) 15%, rgba(38, 55, 77, 0) 49%, rgba(36, 52, 74, .13) 68%, #0a204e 100%), linear-gradient(269deg, #0a204e 0%, rgba(37, 54, 76, .08) 21%, rgba(38, 55, 77, 0) 48%, rgba(37, 52, 76, .05) 74%, #0a204e 100%);

    }

    /* #container_radius {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 30%;
        height: 50%;
        border-radius: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba(255, 255, 255);
        opacity:.1;
    } */

    .row-item>.ceil:nth-child(2) {
        width: 250px !important;
    }

    .row-item>.ceil:nth-child(3) {
        width: 300px !important;
    }

    .header-item:nth-child(3) {
        width: 250px !important;
        margin-left: 50px !important;
    }

    .anchorBL {
        display: none;

    }

    .contant {
        /* position: fixed;
        right: 0;
        bottom: 0px; */
        width: 700px;
        height: 400px;
        background-color: #fff;
    }

    .amap-logo {
        display: none !important;
    }

    .amap-copyright {
        opacity: 0 !important;
    }

    .contant {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .contant_left {
        padding: 0px 20px;
        padding-right: 10px;
        width: 60%;
    }

    .contant_left div {
        margin-top: 20px;
        font-size: 14px;
    }

    .contant_right {
        width: 40%;
        display: flex;
        /* justify-content: space-around; */
        flex-direction: row;
        flex-wrap: wrap;
    }

    .contant_right div {
        width: 50%;
        height: 80px;
        text-align: center;
        margin-top: 40px;
    }

    .contant_right img {
        height: 80px;
    }

    .bm-view-div {
        width: 100%;
        height: 100%;
        margin: 0 auto;
        overflow: hidden;
        border: none !important;
        background: rgb(55, 75, 112);
        position: relative;
    }

    .bm-view {
        width: 100%;
        height: 100%;
        background-color: rgb(24, 31, 37);

    }

    .all-charts {
        height: calc(100% - 150px);
        width: 20%;
        background-color: rgba(2, 29, 67, .2);
        z-index: 2;
        position: absolute;
        left: 0;
        /* top: 5vh; */
        bottom: 0;
        border-top-right-radius: 10px;
        padding: 15px 10px;
        box-sizing: border-box;
        animation: moveRight 1s ease-out;
        animation-fill-mode: forwards;
    }

    @keyframes moveRight {
        0% {
            left: -20%;
        }

        100% {
            left: 0;
        }
    }

    .all-map {
        background: rgb(24, 31, 37);
        width: 100%;
        height: 100%;
    }

    .all-right {
        width: 28%;
        height: calc(100% - 150px);
        background-color: rgba(6, 35, 77, .2);
        position: absolute;
        right: 0;
        /* top: 7%; */
        bottom: 0;
        padding: 15px 10px;
        box-sizing: border-box;
        border-top-left-radius: 10px;
        animation: moveLeft 1s ease-out;
        animation-fill-mode: forwards;
    }

    @keyframes moveLeft {
        0% {
            right: -15%;
        }

        100% {
            right: 0;
        }
    }

    .all-charts h3,
    .all-right h3 {
        color: #fff;
        padding: 3px 0px 3px 8px;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 2px;
        box-sizing: border-box;
        position: relative;
        background: linear-gradient(to right, rgb(43, 87, 203), rgba(252, 171, 1, .6));
        /* 定义沿着 X 和 Y 轴的 2D 倾斜转换*/
    }

    .all-charts h3::after,
    .all-right h3::after {
        content: "";
        position: absolute;
        right: -5px;
        display: block;
        width: 2px;
        height: 100%;
        top: 0;
        background: rgba(252, 171, 1, .6);

    }

    .all-charts-six-box,
    .all-charts-line-box,
    .all-charts-progress-box,
    .all-right-center-box {
        width: 100%;
        padding: 15px;
        background: rgba(77, 166, 255, .3);
        box-sizing: border-box;
        margin-top: 20px;
        border-radius: 20px;
    }

    .all-charts-top,
    .all-charts-six {
        display: flex;
        justify-content: space-between;
        color: #fff;
        text-align: center;
        margin-top: 10px;
        font-size: 10px;
    }

    .all-charts-back {
        width: 30%;
        height: 150px;
        background: rgba(118, 164, 218, 0.4);
        border-radius: 10px;
        position: relative;
    }

    .all-charts-back-child {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    .all-charts-back-child img {
        height: 70px;
    }

    /* .all-charts-back:nth-child(2) >.all-charts-back-child img{
        height:6vh;
    } */

    .all-charts-top p {
        font-size: 10px;
    }


    .all-charts-line {
        display: flex;
        justify-content: space-around;
    }

    .radius_line {
        width: 200px;
        height: 200px;
        background-color: #fff;
        border: 5px solid red;
    }

    .all-charts-progress {
        margin-top: 10px;
        padding: 0 10px;
    }

    .all-charts-progress-title {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: #fff;
        font-size: 14px;
        width: 100%;
    }

    .all-charts-progress-title div:nth-child(1) {
        background-color: #fead02;
        width: 23px;
        height: 17px;
        text-align: center;
        line-height: 17px;
        color: #1953f6;
        font-size: 10px;
        font-weight: bold;
    }

    .all-charts-progress-title div:nth-child(2) {
        display: flex;
        align-items: flex-end;
    }

    .all-charts-progress-title span:nth-child(1) {
        margin-left: 10px;
        font-weight: bold;
        font-size: 12px;
    }

    .all-charts-progress-title span:nth-child(2) {
        font-size: 8px;
        transform: scale(0.8);
    }

    .all-charts-progress-line {
        width: 100%;
        margin: 0 auto;
        margin-top: 5px;
        /* border: 1px solid #01b7fc; */
        height: 15px;
        border-radius: 30px;
        position: relative;
        background-color: #01b7fc;
    }

    .line-border {
        width: 0%;
        height: 100%;
        background-color: #ffad01;
        border-radius: 30px;
        transition: all ease-in-out .5s;
    }

    .all-charts-progress-line span {
        position: absolute;
        right: 0;
        top: -4px;
        font-size: 10px;
        color: #fff;
        margin-top: 3px;
        transition: all .5s ease-in-out;
    }

    .header-item {
        font-size: 10px !important;
    }

    .radius_box {
        display: block;
        width: 13px;
        height: 13px;
        border-radius: 50%;
        background-color: #fdac03;
        margin-top: 9px;
    }

    .rows {
        padding: 0 !important;
    }

    #showBox_box {
        width: 810px;
        background-color: rgba(2, 29, 67, .7);
        border-radius: 10px;
        position: absolute;
        bottom: 50px;
        left: 50%;
        transform: translate(-50%);
        padding: 10px;
        opacity: 0;
        animation: opcShow .5s ease-in-out;
        animation-fill-mode: forwards;
    }

    @keyframes opcShow {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    #showBox_box h4 {
        text-align: center;
        color: #fff;
        font-weight: bold;
        font-size: 20px;
        letter-spacing: 3px;
    }

    .showBox_con {
        color: #fff;
        margin-top: 10px;
        font-size: 15px;
    }

    .carousel_flashlight {
        width: 100%;

        text-align: center;
        margin: 0 auto;
    }

    #swiper {
        width: 100%;
        margin-top: 10px;
    }

    .carousel-3d-slider {
        width: 100%;
        display: flex;
        height: 202px;
        justify-content: center;
        align-items: center;
    }
</style>